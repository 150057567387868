import React from 'react';
import { CSSTransition } from 'react-transition-group';

import style from './style.module.scss';

export default function Lightbox({onClose, children, noPadding, noBorder, show, size}){
    if (!onClose){
        throw new Error('onClose must be assigned to Lightbox');
    }

    let aniTime = 400;

    let contClass = [];
    if (size){
        if (!['small'].includes(size)){
            throw new Error('Unknown Lightbox size: '+size);
        }
        contClass.push(style[size]);
    }
    if (noPadding){
        contClass.push(style.noPadding);
    }
    if (noBorder){
        contClass.push(style.noBorder);
    }

    const nodeRef = React.useRef(null)

    return (
        <CSSTransition
            id={style.wrap}
            in={show}
            timeout={aniTime}
            classNames={{
                enter: style.enterStart,
                enterActive: style.onEnter,
                enterDone: style.onEnter,
                exit: style.exitStart,
                exitActive: style.onExit
            }}
            nodeRef={nodeRef}
        >
            <div id={style.wrap} ref={nodeRef}>
                <div id={style.fadeMask} onClick={onClose}></div>
                <div id={style.content} className={contClass.join(" ")}>
                    {children}
                </div>
            </div>
        </CSSTransition>
    );
}