import React from 'react';

import Utils from 'library/Utils';
import APIRequest from 'library/Request';

import "./style.scss";

import { BorderedBox } from 'components/BorderedBox';
import { Button } from 'components/Button';
import Lightbox from 'components/Lightbox';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            "name": "",
            "email": "",
            "message": "",
            "validateError": "",
            "submitDisable": false,
            "showConfirm": false
        }

        if (!this.props.hasOwnProperty("navLoc")){
            throw new Error("Navigation location required");
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        onLight: true       
    }

    handleChange = event => {
        let state = {};
        state[event.target.name] = event.target.value;

        if (event.target.name === this.state.validateError){
            switch (this.state.validateError) {
                case "name":
                case "message":
                    if (event.target.value !== ""){
                        state.validateError = "";
                    }
                    break;
                
                case "email":
                    if (Utils.validateEmail(event.target.value)){
                        state.validateError = "";
                    }
                    break;
            
                default:
                    break;
            }
        }

        this.setState(state);
    }

    handleSubmit = event => {
        event.preventDefault();
        if (typeof document === `undefined` || this.state.submitDisable) {
            return;
        }

        if (this.state.name === ""){
            this.setState({validateError: "name"});
            return;
        }
        if (!Utils.validateEmail(this.state.email)){
            this.setState({validateError: "email"});
            return;
        }
        if (this.state.message === ""){
            this.setState({validateError: "message"});
            return;
        }

        this.setState({validateError: "", submitDisable: true});

        // Send request
        let req = new APIRequest("contact/", {
            navLoc: this.props.navLoc,
            action: "contact",
            referrer: document.referrer,
            name: this.state.name,
            email: this.state.email,
            message: this.state.message
        },{
            requestType: "POST"
        });
    
        req.makeRequest().then(function(){
            this.setState({showConfirm: true, submitDisable: false});
        }.bind(this));
    }

    render() {
        let nameClass;
        let emailClass;
        let messageClass;

        switch(this.state.validateError){
            case "":
                break;
            case "name":
                nameClass = "invalid"; break;
            case "email":
                emailClass = "invalid"; break;
            case "message":
                messageClass = "invalid"; break;
            default:
                throw new Error('Validation class unknown');
        }

        return (<>
            <BorderedBox onLight={this.props.onLight}>
                <form id='ContactForm' onSubmit={this.handleSubmit}>
                    <input name="name" 
                            placeholder="Name..." 
                            onChange={this.handleChange}
                            value={this.state.name}
                            className={nameClass}
                    />
                    <input id='CFEmailInput' 
                            name="email" 
                            type="email" 
                            placeholder="Email..." 
                            onChange={this.handleChange}
                            value={this.state.email}
                            className={emailClass}
                    />
                    <textarea id='CFTextInput' 
                            name="message"
                            placeholder="Message..."
                            onChange={this.handleChange}
                            value={this.state.message}
                            className={messageClass}
                    />
                    <Button type="CnfmEmailSend" title="Send message" onClick={this.handleSubmit} disable={this.state.submitDisable}/>
                </form>
            </BorderedBox>
            <Lightbox show={this.state.showConfirm}
                        onClose={() => { this.setState({ showConfirm: false }) }}
                        size="small">
                <p style={{"textAlign": "center", "marginBottom": "1em"}}>Thank you for getting in contact, one of the team will respond shortly.</p>
                <Button position="center" type="IntLink" title="Go to home page" adr="/" />
            </Lightbox>
        </>);
    }
}
 
export default ContactForm;