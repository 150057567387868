import React from 'react';

import { Section } from 'components/Section';

import './style.scss';

export function PagePicFTitle({children, id, image, imageAlt, backgroundImg}){
    return (
        <Section id={id} className="PagePicFTitle" style={{backgroundImage: "url(" + backgroundImg + ")"}}>
            <div className="PagePicFCont">
                {children}
            </div>
            <img className='PageFPicTitlePicture' src={image} alt={imageAlt}></img>
        </Section>
    );
}