import React from 'react';

import './style.scss';
import IndyLink from 'components/IndyLink';

export function Button(props){
    // Define variables based on button type
    let mustHave = ['title'];
    let iconClass;
    switch(props.type){
        case "IntLink":
        case "ExtLink":
            mustHave = ['adr', 'title'];
            iconClass = "fa fa-2x fa-arrow-right";
            break;

        case "Dwnld":
            mustHave = [['adr', 'onClick'], 'title'];
            iconClass = "fa fa-2x fa-download";
            break;

        case "CnfmAdd":
            iconClass = "fa fa-2x fa-download";
            break;

        case "CnfmRem":
            iconClass = "fa fa-2x fa-minus";
            break;

        case "CnfmChange":
            iconClass = "fa fa-2x fa-save";
            break;

        case "CnfmUpld":
            iconClass = "fa fa-2x fa-cloud-upload";
            break;

        case "CnfmDel":
            iconClass = "fa fa-2x fa-trash";
            break;

        case "CnfmGen":
            iconClass = "fa fa-2x fa-check";
            break;

        case "CnfmEmailSend":
            iconClass = "fa fa-2x fa-envelope";
            break;

        case "UIAction":
            break;

        default:
            throw new Error("Unknown button type");
    }

    // Check all the required properties have been provided
    for (let i = 0; i < mustHave.length; i++) {
        if (!props.hasOwnProperty(mustHave[i])){
            if (Array.isArray(mustHave[i])){
                let found = false;
                for (let ii = 0; ii < mustHave[i].length; ii++) {
                    if (props.hasOwnProperty( mustHave[i][ii])){
                        if (found){
                            throw new Error('Too many button properties provided');
                        }else{
                            found = true;
                        }
                    }
                }

                if (!found){
                    throw new Error('Missing button one of property: '+mustHave[i].join(", "));
                }
            }else{
                console.log(mustHave[i]);
                throw new Error('Missing button property: '+mustHave[i]);
            }
        }
    }

    if ((props.onClick) && (props.adr)){
        throw new Error('Button can have onclick event or link, not both');
    }

    // Create class string
    let className = props.className + " MshmButton " + props.type + " butSize_" + props.size;
    if (props.disable){
        className = className + " disabled";
    }

    // Create main button
    let button = (
        <div id={props.id} className={className} title={props.caption} onClick={props.onClick}>
            <p>{props.title}</p>
            {iconClass && 
                <span className='buttonIcon'>
                    <i className={iconClass}></i>
                </span>
            }
        </div>
    );

    // Create button wrap class
    let butWrapClass = "butWrap";
    switch(props.position){
        case "leftJustify":
            butWrapClass = butWrapClass + " LHJust"; break;
        case "rightJustify":
            butWrapClass = butWrapClass + " RHJust"; break;
        case "center":
            butWrapClass = butWrapClass + " CenterJust"; break;
        default:
            break;
    }

    return (
        <div className={butWrapClass}>
            {props.adr ? (
                <IndyLink adr={props.adr}>{button}</IndyLink>
            ) : (
                button
            )}
        </div>
    );

}

// Set default props
Button.defaultProps = {
    'disable': false,
    'className': "",
    'size': "small",
    'position': "rightJustify"
};