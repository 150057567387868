import React from 'react';
import { graphql } from "gatsby";

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';

import './about.scss';

import ContactForm from 'components/ContactForm';
import { PagePicFTitle } from 'components/PagePicFTitle';
import { SEO } from 'components/Helmets';

import headerImg from "media/pages/about/HeaderImage.png";
import headerBackground from "media/pages/about/HeaderGrad.png";

export default function AboutPage({data}){
    const pagePath = "/about";

    const previewImg = {
        url: data.file.childImageSharp.fixed.src,
        height: data.file.childImageSharp.fixed.height,
        width: data.file.childImageSharp.fixed.width,
        alt: "Modules icon"
    }

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="About Us - Mashoom"
                description="We have developed modules that address a wide range of use cases. The same mentality runs through everything we do: We are here to get a job done for our customers, no funny business."
                previewImg={previewImg}
                path={pagePath}
            />
            <PagePicFTitle 
                id="AboutTop" 
                image={headerImg}
                imageAlt="About us note pad"
                backgroundImg={headerBackground}>
                    <h1><span>Who</span> are we?</h1>
                    <p className='paraSpaceTop'>Mashoom was born out of organizing a university racing team; every year they had to design and build a single seat racing car and every year data management was a huge problem. Every solution was too complex, too expensive, too rigid. Building your own was simply deemed impossible and commercially it required raising epic amount of investment and swan diving into the market.</p>
                    <p className='paraSpaceTop'>Gradually, Mashoom has proved these opinions wrong; software can be simple, it can be effective, it can be friendly to use and it can be available to all at a fair price. This can all co-exist with a simple business model that puts the customer first and keeps it that way.</p>
                    <p className='paraSpaceTop'>From our engineering starting point, we have developed modules that address a wide range of use cases, from tending documents to calibrating supermarket scales. Mashoom is used at scale and as part of core workflows.</p>
                    <p className='paraSpaceTop'>We intend to keep breaking the stereotypes and perceptions of the tech industry.</p>
            </PagePicFTitle>
            <Section id='MissionStatement'>
                <h2>The same mentality runs through everything we do</h2>
                <div>
                    <ul>
                        <li>Solve our customer's problems, no fussing and no funny business.</li>
                        <li>Always challenge the status quo, we are creating solutions not features.</li>
                        <li>Stable and reliable, no gimmicks or strange business models.</li>
                    </ul>
                </div>
            </Section>
            <Section id="ContactUs">
                <div id="ContactFormCont">
                    <div id="ContactFormText">
                        <h2>Want to <span>get in contact?</span></h2>
                        <h3>Fill in this form and we will get back to you ASAP.</h3>
                        <h3>We will not send you any other emails other than to respond to your query.</h3>
                    </div>
                    <ContactForm onLight={false} navLoc={pagePath}/>
                </div>
            </Section>
        </PageWrap>
    );
}

//eslint-disable-next-line no-undef
export const query = graphql`
    query AboutPreviewImg {
        file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/about/HeaderImage.png"}) {
            childImageSharp {
                fixed(width: 800, height: 400, fit: COVER, cropFocus: CENTER) {
                    src
                    height
                    width
                }
            }
        }
    }
`;